import React, { Component } from "react";
import { withRouter } from "react-router";
import Layout from "../layouts/MainLayout";
import Fetch from "../common/Fetch";
import { withContext } from "../context/Context";
import Link from "../common/Link";

// import Images from "../common/Images";
// const {
//   personalised_1,
//   personalised_2,
//   personalised_3,
//   personalised_4,
//   personalised_5,
// } = Images;

class Personlised extends Component {
  state = {
    isLoading: true,
    data: [],
    message: "",
    prodID: 1,
    prodTitle: "",
    slug: "",
    bannerImage: "",
  };

  componentDidMount() {
    this.initialFetch();
    window.onpopstate = this.initialFetch;
  }

  initialFetch = (slug = "") => {
    let pageSlug = slug ? slug : "";

    if (!slug) {
      const { params } = this.props.match;
      // const { search } = this.props.location;
      pageSlug = params?.id;
      // prodID = queryString.parse(search)?.prodID;
      // prodTitle =(params?.id?.split("-")[0]);

      const prodTitle = pageSlug
        ?.replaceAll("-", " ")
        ?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

      // const prodID = id;
      // const prodTitle = params?.id
      //   ?.replaceAll("-", " ")
      //   ?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
      // this.setState({ prodID, prodTitle, isLoading: true });

      this.setState({ prodTitle, slug: pageSlug, isLoading: true });
    }

    Fetch(`prod/allcategoriesbyslug/${pageSlug}`, null, { method: "get" })
      .then((res) => {
        if (res.success) {
          if (!res?.data?.child_category.length) {
            Fetch(`prod/allcategories/${pageSlug}/productbyslug`, null, {
              method: "get",
            })
              .then((r) => {
                if (r.success) {
                  this.setState({
                    data: r.data,
                    bannerImage: res.data.parent_category[0].bannerimage,
                    isLoading: false,
                  });
                }
              })
              .catch((err) => console.log(err));
            return;
          }

          this.setState({
            data: res.data.child_category,
            bannerImage: res.data.parent_category[0].bannerimage,
            isLoading: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  onClickItems = (data, slug2) => {
    const { slug } = this.state;
    const { history } = this.props;

    let pathname = "";
    if (Array.isArray(data)) {
      pathname = `/shop/${slug}/${data[0].slug}`;
      return history.push(pathname);
    }

    pathname = `/shop/${slug}/${slug2}`;
    return history.push(pathname);
  };

  render() {
    const { prodTitle, isLoading, data, bannerImage } = this.state;

    // let newBanner = "http://api.darksugars.co.uk/storage/51/62f792a4d3f15_WeeO0ruhGn8a7BJaGf06WvTXEf2gXDiB.jpg";

    return (
      <Layout
        title={
          prodTitle
            ? prodTitle.replaceAll("-", " ") + " | Dark Sugar"
            : "Dark Sugar"
        }
        loading={isLoading}
      >
        <section
          style={{ background: `url(${bannerImage}) no-repeat` }}
          className={`shop-banner personalised`}
        >
          <h2>{prodTitle}</h2>
        </section>
        <section className="container shop-section">
          <div className=" flex flex-wrap personalised-section-change">
            {data?.length
              ? data.map((d, i) => (
                  <Link
                    onClick={() =>
                      this.onClickItems(d, d.slug ?? d[0].slug, d.id ?? d[0].id)
                    }
                    key={i}
                    className="personalised-section-inner"
                  >
                    <img
                      src={d.photo ?? d[0].photo}
                      alt={d.name ?? d[0].name}
                    ></img>
                    <div>
                      <h3> {d.name ?? d[0].name}</h3>
                    </div>
                  </Link>
                ))
              : null}
          </div>
        </section>
      </Layout>
    );
  }
}

export default withContext(withRouter(Personlised));
