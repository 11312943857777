import React, { Component } from "react";
import MainLayout from "../layouts/MainLayout";
import { withContext } from "../context/Context";
import { withRouter } from "react-router";
import PrepackedProducts from "../components/PrepackedProducts";
import VoucherComp from "../components/CustomizeGiftVoucher";
import TextArea from "../components/TextArea";
import Auth from "../common/Auth";
import Images from "../common/Images";
import * as queryString from "query-string";
import Fetch from "../common/Fetch";
import Filters from "../components/Filters";
import MainSlider from "../components/Slider";
import {
  priceCalculator,
  addSubtractFxn,
  toggleToastMsg,
} from "../common/utils";
import Popup from "../components/popups/PriceAddWarning";
import ExpressDileveryMsg from "../components/common-comps/ExpressDileveryMsg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const {
  rightArrow,
  step2left,
  load,
  buffer,
  bookmarks,
  checkrect,
  Basket,
  addbutton,
} = Images;

class Products extends Component {
  state = {
    isLoading: true,
    currentPage: "Products",
    pageData: {},
    giftWraps: [],

    productID: "",
    pageTitle: "",

    activeView: 1,
    tableView: false,

    boxPrice: "0",
    boxUnits: "0",
    boxItemLimit: 0,
    basePrice: "",
    boxQty: 1,

    customizeSteps: [
      { label: "Select Your Box", view: 1, completed: 1 },
      { label: "Select Your Item", view: 2, completed: 0 },
      { label: "Last Step", view: 3, completed: 0 },
    ],

    itemType: "",

    boxInfo: {
      itemType: "",
      units: 0,
      id: 0,
      price: "0",
      img: "",
      closeboxImg: "",
    },

    boxItemObj: {},
    tempTruffleObj: {},
    truffleBoxArr: [],
    boxItemsArr: [],
    itemInfo: [],

    itemsWithTypeTagIds: [],
    itemsObjWithImg: {},

    totalAddedItemsInBox: 0,
    ingredients: "",
    ingredDesc: {
      title: "Ingredients",
      desc: "",
    },

    itemDesc: {
      title: "",
      desc: "",
    },
    noOfBoxes: [{ label: "Box 1", uniqueID: 1, photo: checkrect }],

    truffleBoxIndex: 0,

    msgObj: {},
    messageIndex: 0,

    messageArr: [],

    giftIndex: 0,
    giftWrapArr: [],
    giftIdsObj: {},
    selectedTagIds: [],

    selectedTypeIds: [],
    enableMsgBox: false,
    enableGiftWrap: false,

    tableView: 1,
    ingredientsView: 0,

    cartBtnText: "Add To Basket",
    btnLoader: false,
    errMsg: "",

    isItemBookmarked: 0,

    filteredItems: [],

    allTags: [],
    allTypes: [],

    priceAddWarningPopup: {
      msgBox: { show: false, shownOnce: 0 },
      giftWrap: { show: false, shownOnce: 0 },
    },

    pageSlug: "",
  };

  componentDidMount() {
    this.onRouteChange();
  }

  componentDidUpdate(nextProps) {
    const { id2 } = nextProps.match.params;

    if (!this.state.isLoading && id2 && this.state.pageSlug) {
      if (id2 !== this.state.pageSlug) {
        this.onRouteChange();
      }
    }
  }

  onRouteChange = () => {
    const { location, match, history } = this.props;
    const { search } = location;
    const { params } = match;
    const { page } = queryString.parse(search);
    const { pathname } = location;

    const slug = params?.id2;

    const productID = Number(params?.id2?.split("-")[0]);

    const pageTitle = slug?.includes("-")
      ? slug
          ?.replaceAll("-", " ")
          ?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
      : slug.replace(/\b\w/g, (m) => m.toUpperCase());

    this.setState({ currentPage: page, productID, pageTitle, pageSlug: slug });

    Fetch("prod/allgiftwraps", null, { method: "get" })
      .then((res) => {
        if (res?.success) {
          this.setState({ giftWraps: res.data });
        }
      })
      .catch((err) => console.error(err));

    if (pathname.includes("personalised")) {
      this.productsPageAPI(slug);
      return;
    }

    if (pathname.includes("gift-vouchers")) {
      this.productsPageAPI(slug);
      this.setState({ currentPage: "vouchers" });
      return;
    }

    if (pathname?.toLowerCase().includes("prepacked")) {
      this.setState({ currentPage: "prepacked-items" });
      return;
    }

    return history.push("/404");
  };

  /**
   *
   * @param {Number} id
   * @param {String} imgUrl
   * @param {Number} itemAdded
   */
  // updateLocalItemsObj=(id, imgUrl, itemAdded, itemName, className)=>{
  //   const innerObj = {};
  //   innerObj["imgUrl"] = imgUrl;
  //   innerObj["class"] = className;
  //   innerObj["itemAdded"] = itemAdded;
  //   innerObj["id"] = id;
  //   innerObj["name"] = itemName;
  //   return innerObj;
  // }

  productsPageAPI = (slug) => {
    Fetch(`prod/productbyslug/${slug}`, null, { method: "get" })
      .then((res) => {
        if (res?.success) {
          // const [{ boxes, items }] = res.data;
          // if (slug !== "gift-vouchers") {
          //   this.chooseBoxSize(boxes?.[0], items);
          // }
          // this.createItemsArr(items);
          // this.setState({
          //   pageData: res.data[0],
          //   isLoading: false,
          //   filteredItems: items,
          // });
          this.initialItemsArrangement(slug, res.data[0]);
        }
      })
      .catch((err) => console.log(err));
  };

  initialItemsArrangement = (slug, pageData) => {
    const { boxes, items } = pageData;

    if (slug !== "gift-vouchers") {
      this.chooseBoxSize(boxes?.[0], items);
    }

    this.createItemsArr(items);
    this.setState({
      pageData,
      isLoading: false,
      filteredItems: items,
    });
  };

  createItemsArr = (arr) => {
    let finalItemsArr = [];
    let temp = [];
    let temp2 = [];

    arr.forEach((d) => {
      if (d?.tags?.length) {
        d.tags.forEach((dd) => {
          temp.push(dd);
        });
      }

      if (d?.types?.length) {
        d.types.forEach((dd) => {
          temp2.push(dd);
        });
      }

      let newTag = d.tags.map((dd) => dd.id);
      let newType = d.types.map((dd) => dd.id);
      let newObj = { ...d, tagsArr: newTag, typesArr: newType };
      finalItemsArr.push(newObj);
    });

    const key = "id";
    const tagsUniqueByKey = [
      ...new Map(temp.map((item) => [item[key], item])).values(),
    ];
    const typesUniqueByKey = [
      ...new Map(temp2.map((item) => [item[key], item])).values(),
    ];

    this.setState({
      itemsWithTypeTagIds: finalItemsArr,
      filteredItems: finalItemsArr,

      allTags: tagsUniqueByKey,
      allTypes: typesUniqueByKey,
    });
  };

  createTruffleArray = (count, arr, from = "boxes", options) => {
    let newItemCount = count;
    let isNotPerfectMultiple = count % 25;

    if (isNotPerfectMultiple) {
      newItemCount = newItemCount + (25 - isNotPerfectMultiple);
    }

    let finalBoxesCount = newItemCount / 25;

    // if(options?.slug === "pipette"){
    //   isNotPerfectMultiple  = count % 12;
    //   if(isNotPerfectMultiple){
    //     newItemCount = newItemCount + (12 - isNotPerfectMultiple);
    //   }
    //   finalBoxesCount = newItemCount / 12
    // };
    // let aa = Math.round(count / 25)

    let tempArr = [];
    let obj = {};
    let num = 25;
    let b = 0;

    for (var i = 0; i < finalBoxesCount; i++) {
      obj[i] = arr.filter((d, ii) =>
        i == 0 ? ii < num : ii > b - 1 && ii < num
      );
      b = num;
      num *= 2;
      tempArr.push(i);
    }

    this.setState({ tempTruffleObj: obj });

    if (from === "items") {
      const { truffleBoxIndex } = this.state;
      let filteredArr = obj?.[truffleBoxIndex]?.filter((d) => d.itemAdded);

      if (filteredArr?.length === 25) {
        if (truffleBoxIndex < tempArr?.length - 1) {
          this.setState((state) => ({
            truffleBoxIndex: state.truffleBoxIndex + 1,
          }));

          if (this.truffleBoxSlider) {
            this.truffleBoxSlider.slickNext();
          }
        }
      }
    }

    return tempArr;
  };

  createNewItemsArr = (qty, itemsArr) => {
    let newArr = [];
    let newIndex;
    for (var i = 0; i < qty; i++) {
      if (i < itemsArr.length) {
        newArr.push(itemsArr[i]);
      } else {
        if (newIndex === itemsArr.length - 1) {
          newIndex = 0;
          newArr.push(itemsArr[newIndex]);
        } else {
          if (newIndex === undefined) {
            newIndex = 0;
            newArr.push(itemsArr[newIndex]);
          } else {
            newIndex += 1;
            newArr.push(itemsArr[newIndex]);
          }
        }
      }
    }

    this.setState({ newItemsArr: newArr });
    return newArr;
  };

  /**
   * @param {Object} obj
   */
  chooseBoxSize = (obj, items = []) => {
    const { itemsObjWithImg, pageData } = this.state;
    const { name } = pageData;
    const copyCustomizeSteps = [...this.state.customizeSteps];
    const aa = { ...copyCustomizeSteps[0] };
    aa.completed = 1;
    copyCustomizeSteps[0] = aa;

    const boxItemCount = Number(obj?.unit);

    let tempArr = [];
    let copyItemsObj = { ...itemsObjWithImg };

    for (var i = 0; i < boxItemCount; i++) {
      let tempObj = {};
      // const obj = this.updateLocalItemsObj(obj.id, "", 0, "", "");
      tempObj.imgUrl = "";
      tempObj.class = "";
      tempObj.itemAdded = 0;
      tempObj.id = obj.id;
      tempObj.name = obj.name;
      tempObj.unique_id = i;
      // copyItemsObj[i] = obj
      // tempArr.push(obj);
      copyItemsObj[i] = tempObj;
      tempArr.push(tempObj);
    }

    const truffleBoxArr = this.createTruffleArray(boxItemCount, tempArr);

    this.createNewItemsArr(boxItemCount, items);

    this.setState({
      boxInfo: {
        itemType: name,
        units: boxItemCount,
        id: obj.id,
        price: parseFloat(obj.price),
        featureImage: obj.featureimage,
        img: obj.photo,
        closeboxImg: obj.closebox,
      },

      boxItemLimit: boxItemCount,
      basePrice: obj.price,
      itemType: name,
      boxItemsArr: tempArr,
      customizeSteps: copyCustomizeSteps,

      itemsObjWithImg: copyItemsObj,
      totalAddedItemsInBox: 0,

      truffleBoxArr,
    });
  };

  /**
   *
   * @param {Object} obj
   * @param {String} from
   * @param {Number} u_id
   * @returns
   */
  setItemsInBox = (obj, from = "items", u_id) => {
    const {
      boxItemsArr,
      boxItemObj,
      totalAddedItemsInBox,
      boxItemLimit,

      truffleBoxIndex,
      customizeSteps,
      activeView,
      boxInfo,
    } = this.state;

    let boxItemQty = totalAddedItemsInBox;
    const copyArr = [...boxItemsArr];
    let copyItemInfo = { ...boxItemObj };

    const isBoxFull =
      copyArr?.filter((d) => d.imgUrl)?.length === copyArr?.length;

    if (from === "items" && isBoxFull) {
      return window.handleToastMsg(true, "Maximum items limit reached");
    }

    if (from === "box") {
      if (obj.id in copyItemInfo) {
        let innerObj = { ...copyItemInfo[obj.id] };

        if (innerObj["item_count"] > 1) {
          innerObj["item_count"] = innerObj["item_count"] - 1;
          copyItemInfo[obj.id] = innerObj;
        } else {
          delete copyItemInfo[obj.id];
        }
        boxItemQty = boxItemQty - 1;
      }

      this.setState({
        boxItemObj: copyItemInfo,
        totalAddedItemsInBox: boxItemQty,
      });

      for (let [key, val] of copyArr.entries()) {
        if (val.unique_id === u_id) {
          let copyVal = { ...val };
          copyVal["imgUrl"] = "";
          copyVal["itemAdded"] = 0;
          copyVal["name"] = "";
          copyVal["id"] = 0;
          copyVal["unique_id"] = u_id;
          val = copyVal;
          copyArr[key] = copyVal;

          this.createTruffleArray(boxItemLimit, copyArr, from);
          this.setState({ boxItemsArr: copyArr });
          return;
        }
      }
      return;
    }

    if (obj.id in copyItemInfo) {
      let innerObj = { ...copyItemInfo[obj.id] };
      innerObj["items_id"] = obj.id;
      innerObj["item_count"] = innerObj["item_count"] + 1;
      copyItemInfo[obj.id] = innerObj;
    } else {
      copyItemInfo[obj.id] = { items_id: obj.id, item_count: 1 };
    }

    boxItemQty = boxItemQty + 1;

    this.setState({
      boxItemObj: copyItemInfo,
      ingredDesc: { title: "Ingredients", desc: obj?.ingredients },
      itemDesc: { title: obj.name, desc: obj?.description },
    });

    for (let [key, val] of copyArr.entries()) {
      if (!val["itemAdded"]) {
        val["imgUrl"] = obj.productimage;
        val["itemAdded"] = 1;
        val["name"] = obj.name;
        val["id"] = obj.id;
        val["unique_id"] = key;
        copyArr[key] = val;

        this.createTruffleArray(boxItemLimit, copyArr, from, obj);

        this.setState({
          boxItemsArr: copyArr,
          totalAddedItemsInBox: boxItemQty,
        });
        return;
      }
    }
  };

  updateGiftWrapIds = (arr) => {
    this.setState({ giftWrapArr: arr });
  };

  updateMessageArr = (msgArr) => {
    this.setState({ messageArr: msgArr });
  };

  refreshBoxItems = () => {
    const { boxInfo, pageData, itemsWithTypeTagIds, filteredItems } =
      this.state;
    let obj = pageData?.boxes?.filter((d) => d.id === boxInfo?.id)?.[0];
    this.setState({ boxItemObj: {} });

    this.chooseBoxSize(obj, filteredItems);
  };

  randmizeBoxItems = () => {
    const {
      boxItemsArr,
      boxItemObj,
      boxItemLimit,
      newItemsArr,
      filteredItems,
    } = this.state;
    const copyBoxItemsArr = [...boxItemsArr];
    const copyItemObj = { ...boxItemObj };

    const isItemAdded = copyBoxItemsArr?.filter((d) => d.imgUrl)?.length;

    if (isItemAdded) {
      let length = copyBoxItemsArr.length;
      for (let i = length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [copyBoxItemsArr[i], copyBoxItemsArr[j]] = [
          copyBoxItemsArr[j],
          copyBoxItemsArr[i],
        ];
      }
      this.setState({ boxItemsArr: copyBoxItemsArr });
      return;
    }

    for (let [key, val] of copyBoxItemsArr.entries()) {
      let copyVal = { ...val };
      if (key < newItemsArr.length) {
        copyVal["imgUrl"] = newItemsArr[key].productimage;
        copyVal["itemAdded"] = 1;
        copyVal["name"] = newItemsArr[key].name;
        copyVal["id"] = newItemsArr[key].id;
        copyVal["unique_id"] = key;
        val = copyVal;
        copyBoxItemsArr[key] = copyVal;
      } else {
        copyVal["imgUrl"] = "";
        copyVal["itemAdded"] = 0;
        copyVal["name"] = "";
        copyVal["id"] = 0;
        copyVal["unique_id"] = key;
        val = copyVal;
        copyBoxItemsArr[key] = copyVal;
      }
    }

    this.createTruffleArray(boxItemLimit, copyBoxItemsArr);

    newItemsArr.forEach((d) => {
      if (d.id in copyItemObj) {
        copyItemObj[d.id]["item_count"] = copyItemObj[d.id]["item_count"] + 1;
      } else {
        copyItemObj[d.id] = { items_id: d.id, item_count: 1 };
      }
    });

    this.setState({
      truffleBoxIndex: 1,
      boxItemsArr: copyBoxItemsArr,
      boxItemObj: copyItemObj,
      totalAddedItemsInBox: copyBoxItemsArr?.filter((d) => d.imgUrl)?.length,
    });
  };

  handleGiftIds = (key, value) => {
    const { giftIdsObj } = this.state;
    const copyGiftObj = { ...giftIdsObj };
    if (key in copyGiftObj) {
      delete copyGiftObj[key];
      this.updateGiftWrapIds(Object.values(copyGiftObj));
    }

    copyGiftObj[key] = { gift_id: value };
    this.setState({ giftIdsObj: copyGiftObj });

    this.updateGiftWrapIds(Object.values(copyGiftObj));
    return;
  };

  handleMessage = (msg) => {
    const { msgObj, messageIndex } = this.state;
    const copyMsgObj = { ...msgObj };
    copyMsgObj[messageIndex] = { message: msg };

    this.setState({ msgObj: copyMsgObj });
    this.updateMessageArr(Object.values(copyMsgObj));
    return;
  };

  handleMsgAndGiftWrap = (_evt) => {
    const { name, checked } = _evt.target;

    if (name === "message") {
      this.setState({ enableMsgBox: checked });
      return;
    }

    this.setState({ enableGiftWrap: checked });
    return;
  };

  /**
   *
   * @param {String} section
   * @param {Number} id
   */
  handleBoxesIndex = (section, index) => {
    const { enableMsgBox, priceAddWarningPopup, enableGiftWrap } = this.state;

    if (section === "message" && enableMsgBox && !priceAddWarningPopup?.msgBox?.shownOnce) {
      this.setState({
        priceAddWarningPopup: {
          ...this.state.priceAddWarningPopup,
          msgBox: { show: true, shownOnce: 0  },
        },
      });
      return;
    }

    if (section === "gifts" && enableGiftWrap && !priceAddWarningPopup?.giftWrap?.shownOnce) {
      this.setState({
        priceAddWarningPopup: {
          ...this.state.priceAddWarningPopup,
          giftWrap: { show: true, shownOnce: 0 },
        },
      });
      return;
    }

    if (section === "message") {
      this.setState({ messageIndex: index });
      return;
    }

    this.setState({ giftIndex: index });
    return;
  };

  onViewChange = (view) => {
    const { boxInfo, activeView, totalAddedItemsInBox, customizeSteps } =
      this.state;
    const copyCustomizeSteps = [...customizeSteps];

    if (typeof view === "number" && view) {
      if (view === 2 && !boxInfo?.units) return;
      if (view === 3 && totalAddedItemsInBox !== boxInfo?.units) return;
      if (activeView < view) {
        let obj1 = copyCustomizeSteps[activeView - 1];
        let obj2 = copyCustomizeSteps[view - 1];
        obj1.completed = 1;
        obj2.completed = 1;

        copyCustomizeSteps[activeView - 1] = obj1;
        copyCustomizeSteps[view - 1] = obj2;
      }
      if (activeView > view) {
        let obj1 = copyCustomizeSteps[activeView - 1];
        let obj2 = copyCustomizeSteps[view - 1];
        obj1.completed = 0;
        obj2.completed = 1;

        copyCustomizeSteps[activeView - 1] = obj1;
        copyCustomizeSteps[view - 1] = obj2;
      }
      this.setState({ activeView: view, customizeSteps: copyCustomizeSteps });
      return;
    }

    if (activeView === 1 && !boxInfo?.units) return;
    if (activeView === 1 && boxInfo.units) {
      let aa = copyCustomizeSteps[1];
      aa.completed = 1;
      copyCustomizeSteps[1] = aa;
      this.setState({ customizeSteps: copyCustomizeSteps });
    }

    if (activeView === 2 && totalAddedItemsInBox !== boxInfo?.units) {
      toggleToastMsg(true, "Please fill the box completely");
      return;
    }

    if (activeView === 2 && totalAddedItemsInBox === boxInfo?.units) {
      let aa = copyCustomizeSteps[2];
      aa.completed = 1;
      copyCustomizeSteps[2] = aa;
      this.setState({ customizeSteps: copyCustomizeSteps });
    }
    if (activeView < 3) {
      this.setState({ activeView: activeView + 1 });
    }
    return;
  };

  priceCalculatorFxn = (type, qty) => {
    const { basePrice, noOfBoxes, cartBtnText } = this.state;
    if (cartBtnText === "Go To Basket") return;
    let copyBoxNo = [...noOfBoxes];

    let purchaseUnits = addSubtractFxn(type, qty, 3);
    let itemPrice = priceCalculator(basePrice, purchaseUnits);

    if (type === "inc") {
      if (!copyBoxNo?.length) {
        copyBoxNo = [{ label: "Box 1", uniqueID: 1, photo: checkrect }];
      } else if (copyBoxNo?.length > 0 && copyBoxNo?.length < 3) {
        let obj = {
          label: `Box ${qty + 1}`,
          uniqueID: qty + 1,
          photo: checkrect,
        };

        copyBoxNo.push(obj);
      }
    }

    if (type === "dec") {
      copyBoxNo?.pop();
    }

    this.setState({
      noOfBoxes: copyBoxNo,
      boxQty: purchaseUnits,
      boxInfo: { ...this.state.boxInfo, price: itemPrice },
    });
  };

  leftSideDesc = (list, obj) => {
    const filteredData = list?.filter((d) => d.imgUrl);
    let arr = [];
    if (filteredData?.length) {
      filteredData.forEach((d, i) => {
        if (d.id in obj) {
          let str = `X${obj[d.id]["item_count"]} ${d.name}`;
          if (!arr.includes(str)) {
            arr.push(str);
          }
        }
      });

      return arr.toString();
    }

    return "";
  };

  fetchFavouriteAPI = () => {
    const {
      boxInfo,
      pageData,
      boxItemObj,
      boxQty,
      giftWrapArr,
      boxItemLimit,
      boxItemsArr,
      isItemBookmarked,
    } = this.state;

    const { isUserLogin } = this.props.context;

    if (!isUserLogin) {
      window.handleToastMsg(
        true,
        "Login Required in order to add product to favourites"
      );
      setTimeout(() => {
        window.handleToastMsg(false, "");
      }, 3000);
      return;
    }

    let itemsInBox = boxItemsArr?.filter((d) => d.itemAdded)?.length;

    if (itemsInBox !== boxItemLimit) {
      window.handleToastMsg(true, "Please fill the box completely");
      setTimeout(() => {
        window.handleToastMsg(false, "");
      }, 2000);
      return;
    }

    if (isItemBookmarked) {
      window.handleToastMsg(true, "Already added to favourites");

      setTimeout(() => {
        window.handleToastMsg(false, "");
      }, 2000);
      return;
    }

    const params = {
      boxes: [
        {
          box_id: boxInfo?.id,
          box_count: boxQty,
        },
      ],

      product_id: pageData?.id,
      category_id: pageData?.category?.id,
      gifts: giftWrapArr,
      tags: [],
      messages: [],
      price: boxInfo?.price,
      size_id: "",
      items: Object.values(boxItemObj),
    };

    return Fetch("prod/favourites/store", { ...params }, { sendToken: true })
      .then((res) => {
        if (res?.success) {
          this.setState({ isItemBookmarked: 1 });
          window.handleToastMsg(true, res?.message);
          setTimeout(() => {
            window.handleToastMsg(false, "");
          }, 3000);
          return;
        }
      })
      .catch((err) => console.error(err));
  };

  addItemToCart = (inStock) => {
    const {
      pageData,
      boxInfo,
      boxItemObj,
      boxQty,
      messageArr,
      giftWrapArr,
      cartBtnText,
      boxItemsArr,
      selectedTagIds,
      selectedTypeIds,
      basePrice,
    } = this.state;

    if (!inStock) return;

    const { isUserLogin, getAllInCart } = this.props.context;
    if (cartBtnText === "Go To Basket") return this.props.history.push("/cart");

    const params = {
      boxes: [
        {
          box_id: boxInfo?.id,
          box_count: boxQty,
        },
      ],
      product_id: pageData?.id,
      category_id: pageData?.category?.id,
      gifts: giftWrapArr,
      counts: boxQty,
      tags: selectedTagIds,
      // types: selectedTypeIds,
      messages: messageArr,
      price: this.calculateFinalPrice(boxInfo?.price, messageArr, giftWrapArr),
      size_id: "",
      items: Object.values(boxItemObj),
    };

    if (!isUserLogin) {
      let localData = Auth.handleLocalStorage("temp", null, "get");

      const items = pageData?.items,
        boxes = [
          {
            box: [{ id: boxInfo?.id }],
            count: boxQty,
          },
        ],
        product = {
          name: pageData?.name,
          photo: pageData?.photo,
          note: pageData?.note,
          description: pageData?.description,
          id: pageData?.id,
        },
        category = { ...pageData?.category, name: pageData?.category?.name };

      let finalItemsArr = [];

      items.forEach((d) => {
        if (d.id in boxItemObj) {
          let obj = { count: boxItemObj[d.id]?.item_count, item: [{ ...d }] };
          finalItemsArr.push(obj);
        }
      });

      let cart_id = Math.floor(Math.random() * 1000 + 1);

      const finalObj = {
        boxes,
        // parent_category,
        id: cart_id,
        cart_id,
        basePrice: basePrice,
        product,
        category,
        count: boxQty,
        items: finalItemsArr,
        price: this.calculateFinalPrice(
          boxInfo?.price,
          messageArr,
          giftWrapArr
        ),
        params,
      };

      localData.push(finalObj);
      Auth.handleLocalStorage("temp", localData, "set");
      this.setState({ cartBtnText: "Go To Basket" });
      window.handleToastMsg(true, "Item Added Successfully");
      getAllInCart(false);
      return;
    }

    this.setState({ btnLoader: true });

    Fetch("prod/cart/add", { ...params }, { sendToken: true })
      .then((res) => {
        if (res?.success) {
          this.setState({ btnLoader: false, cartBtnText: "Go To Basket" });
          getAllInCart(true);
          window.handleToastMsg(true, "Item Added Successfully");
        } else {
          this.setState({ btnLoader: false, cartBtnText: "Add To Basket" });
          window.handleToastMsg(true, "Something Went Wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  calculateFinalPrice = (basePrice, messsageArr, giftWrapArr) => {
    let arr = [...messsageArr, ...giftWrapArr];
    if (arr?.length) {
      let extraPrice = 1.5 * arr?.length;
      return extraPrice + Number(basePrice);
    }
    return Number(basePrice);
  };

  updateTagAndTypeIds = (arr, identifier) => {
    if (identifier === "tags") {
      this.setState({ selectedTagIds: arr });
      return;
    }
    this.setState({ selectedTypeIds: arr });
    return;
  };

  updateFilteredItems = (items) => {
    const { pageData, boxInfo } = this.state;
    let boxID = boxInfo.id;
    let selectedBox = pageData.boxes.filter((d) => +d.id === +boxID)[0];

    const filteredItems = items?.length ? items : pageData.items;
    this.setState({ filteredItems });

    this.chooseBoxSize(selectedBox, filteredItems);
  };

  render() {
    const {
      isLoading,
      currentPage,
      pageTitle,
      pageData,

      activeView,
      customizeSteps,
      boxItemsArr,

      boxInfo,
      boxItemObj,

      itemDesc,
      ingredDesc,

      itemsObjWithImg,
      tempTruffleObj,

      messageIndex,
      giftIndex,
      boxQty,
      enableMsgBox,
      enableGiftWrap,
      noOfBoxes,
      tableView,

      btnLoader,
      cartBtnText,

      msgObj,
      giftIdsObj,

      truffleBoxArr,
      ingredientsView,
      truffleBoxIndex,

      isItemBookmarked,
      giftWraps,

      basePrice,

      itemsWithTypeTagIds,
      filteredItems,
      allTags,
      allTypes,
      priceAddWarningPopup,
    } = this.state;

    const {
      slug,
      boxes,
      // tags,
      // types,
      contentimage,
      name,
      note,
      description,

      items,
      leftimage,
      left_text_title,
      left_text_description,

      rightimage,
      right_text_title,
      right_text_description,
      is_stock,
    } = pageData;

    const sortedBoxes =
      boxes?.sort((a, b) => Number(a.unit) - Number(b.unit)) || [];

    const settings = {
      arrow: false,
      slidesToShow: 1,
      className: "",
      infinite: false,
      slidesToScroll: 1,
      autoplay: false,
      dots: false,
      centerMode: false,
      slide: "div",
      arrows: false,
    };

    const addedItemsCount = boxItemsArr?.length
      ? boxItemsArr.filter((d) => d.itemAdded)?.length
      : 0;

    if (currentPage === "prepacked-items") {
      return <PrepackedProducts slug={"abc"} />;
    }

    // console.log({boxInfo})
    return (
      <MainLayout
        title={pageTitle.replaceAll("-", " ") + " | Dark Sugar"}
        loading={isLoading}
      >
        <section className="truffle">
          {currentPage === "vouchers" ? (
            <VoucherComp {...this.state} />
          ) : (
            <div className="container">
              <div className="flex step-top">
                <div className="truffle-head width-100">{name}</div>
                <div
                  className="truffle-next flex align-center step-next"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.onViewChange(e)}
                >
                  Next
                  <img src={rightArrow} alt="arrow"></img>
                </div>
              </div>
              <div className={`truffle-step flex align-center`}>
                <div className={"truffle-stepes"}>
                  <div className="flex pearl-step-inner text-upper">
                    <li> select your box</li>
                    <li> select your {name}</li>
                    <li>last step</li>
                  </div>

                  <ul className="flex">
                    {customizeSteps?.map((d, i) => (
                      <li
                        key={i}
                        className={
                          d.completed
                            ? "truffle-step-orange pointer"
                            : "pointer"
                        }
                        onClick={() => this.onViewChange(i + 1)}
                      >
                        {" "}
                        <span>{i + 1}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {activeView === 3 ? (
                <div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="header-order-list header-order-border  mb1 flex align-center justify-space">
                      <div className="">{name}</div>
                      <div className="table-price  flex align-center">
                        <span></span>
                        {boxInfo.units}
                      </div>
                    </div>

                    <div className="flex">
                      <div className="header-order-left pearl-order-left">
                        <img src={boxInfo.closeboxImg} alt=""></img>
                        <div
                          className="flex justify-space table-edit pointer"
                          onClick={() => this.onViewChange(2)}
                        >
                          Edit
                        </div>
                      </div>
                      <div className="header-order-right">
                        <ul>
                          {this.leftSideDesc(boxItemsArr, boxItemObj)?.length
                            ? this.leftSideDesc(boxItemsArr, boxItemObj)
                                ?.split(",")
                                ?.map((d, i) => <li key={i}>{d}</li>)
                            : null}
                        </ul>

                        <div>
                          <div className="header-order-person  pb1">
                            Personalised message Gift wrapping
                          </div>

                          <div className="flex justify-space header-order-price pt1 align-center">
                            <div className="table-qty flex align-center">
                              <button
                                className="btn btn-plus-minus"
                                onClick={() =>
                                  this.priceCalculatorFxn("inc", boxQty)
                                }
                              >
                                +
                              </button>
                              <span>{boxQty}</span>
                              <button
                                className="btn btn-plus-minus"
                                onClick={() =>
                                  this.priceCalculatorFxn("dec", boxQty)
                                }
                              >
                                -
                              </button>
                            </div>
                            <div className="header-order-rate">
                              {/* £{boxInfo?.price} */}£
                              {parseFloat(basePrice)?.toFixed(2)}
                              {/* {this.calculateFinalPrice(basePrice, messageArr, giftWrapArr)} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                    <div className="truffle-listing">
                      <ul className="listing">
                        <li className={enableMsgBox ? "" : "line-through"}>
                          <input
                            type="checkbox"
                            id="alcohal"
                            name="message"
                            checked={enableMsgBox}
                            onChange={this.handleMsgAndGiftWrap}
                          />
                          <label htmlFor="alcohal">
                            <span></span>
                            Personalised Message
                          </label>
                        </li>

                        {giftWraps?.length ? (
                          <li className={enableGiftWrap ? "" : "line-through"}>
                            <input
                              type="checkbox"
                              id="DaIry"
                              name="giftWrap"
                              checked={enableGiftWrap}
                              onChange={this.handleMsgAndGiftWrap}
                            />
                            <label htmlFor="DaIry">
                              <span></span>Gift Wrap
                            </label>
                          </li>
                        ) : null}
                      </ul>
                    </div>

                    <div className={"product-message row"}>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div
                          className={
                            enableMsgBox
                              ? "row product-active"
                              : "row product-inactive"
                          }
                        >
                          {noOfBoxes?.length
                            ? noOfBoxes.map((d, i) => (
                                <div
                                  className="col-xl-12 col-lg-12 col-md-12 col-sm-12 "
                                  key={i}
                                  onClick={() =>
                                    this.handleBoxesIndex("message", i)
                                  }
                                >
                                  <div
                                    className={
                                      enableMsgBox && messageIndex === i
                                        ? "product-rect product-active"
                                        : "product-rect product-inactive"
                                    }
                                  >
                                    <img src={d.photo} alt="" />
                                    {d.label}
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>

                        {noOfBoxes?.length
                          ? noOfBoxes.map((d, i) =>
                              messageIndex === i ? (
                                <TextArea
                                  updateParentFxn={this.handleMessage}
                                  disabled={!enableMsgBox}
                                  stopTyping={
                                    !priceAddWarningPopup.msgBox.shownOnce
                                  }
                                  beforeOnChange={() => {
                                    if (
                                      !priceAddWarningPopup?.msgBox?.shownOnce
                                    ) {
                                      this.setState({
                                        priceAddWarningPopup: {
                                          ...priceAddWarningPopup,
                                          msgBox: { show: true, shownOnce: 0 },
                                        },
                                      });
                                    }
                                  }}
                                  value={msgObj?.[messageIndex]?.["message"]}
                                />
                              ) : null
                            )
                          : null}
                      </div>

                      {noOfBoxes?.length ? (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="row">
                            {noOfBoxes?.length && giftWraps?.length
                              ? noOfBoxes.map((d, i) => (
                                  <div
                                    className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                                    key={i}
                                    onClick={() =>
                                      this.handleBoxesIndex("gifts", i)
                                    }
                                  >
                                    <div
                                      className={
                                        enableGiftWrap && giftIndex === i
                                          ? "product-rect product-active"
                                          : "product-rect product-inactive"
                                      }
                                    >
                                      <img src={d.photo} />
                                      {d.label}
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>

                          {giftWraps?.length ? (
                            <div className="flex product-message-img">
                              {giftWraps.map((d, i) => (
                                <img
                                  key={i}
                                  src={d.photo}
                                  alt={d.name}
                                  className={
                                    giftIdsObj?.[giftIndex]?.["gift_id"] ===
                                    d.id
                                      ? "product-active product-active-border"
                                      : "product-inactive"
                                  }
                                  onClick={() => {
                                    if (!enableGiftWrap) return;

                                    if (!priceAddWarningPopup.giftWrap.shownOnce) {
                                      this.setState({
                                        priceAddWarningPopup: {
                                          ...priceAddWarningPopup,
                                          giftWrap: {show: true, shownOnce: 0}
                                        },
                                      });
                                      return;
                                    }
                                    return this.handleGiftIds(giftIndex, d.id);
                                  }}
                                ></img>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt5 width-100 inline-block margin-top-8">
                      <button
                        className={`btn btn-add ml-auto flex align-center ${
                          !is_stock
                            ? "product-inactive"
                            : boxQty
                            ? "product-active"
                            : "product-inactive"
                        }`}
                        onClick={() => this.addItemToCart(is_stock)}
                        disabled={!boxQty}
                      >
                        <img src={Basket} alt="" />{" "}
                        {btnLoader
                          ? "Loading..."
                          : is_stock
                          ? cartBtnText
                          : "Out of stock"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : activeView === 2 ? (
                <div id="Step_2" className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                    <div className="truffle-inner-head flex justify-space">
                      Box
                      <div>PRICE</div>
                    </div>
                    <div className="flex justify-space">
                      <div className="pearl-price-left flex justify-space">
                        <div
                          class={`flex justify-space align-start ${
                            slug !== "pearls" ? "square-box-left" : ""
                          }`}
                        >
                          {sortedBoxes?.length
                            ? sortedBoxes.map((d, i) => (
                                <button
                                  key={i}
                                  className={`btn ${
                                    d.id === boxInfo.id
                                      ? "product-active"
                                      : "product-inactive"
                                  }`}
                                  onClick={() =>
                                    this.chooseBoxSize(d, filteredItems)
                                  }
                                >
                                  <img key={i} src={d.photo} alt={d.name} />
                                </button>
                              ))
                            : null}
                        </div>
                      </div>

                      <div className="pearl-price">
                        £{boxInfo.price}
                        <span>{boxInfo.units} Items</span>
                      </div>
                    </div>
                    <div className="truffle-inner-head flex justify-space mt2">
                      IN BOX
                      <div>
                        {addedItemsCount}/{boxItemsArr?.length}
                      </div>
                    </div>

                    <div className="mt1 pearl-pitch">
                      {this.leftSideDesc(boxItemsArr, boxItemObj)}
                    </div>
                  </div>

                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 overflow-hidden">
                    <div className="truffle-inner-head flex justify-space">
                      Your Box
                      <div className="flex justify-space pearl-two step-btns">
                        <button className="btn" onClick={this.randmizeBoxItems}>
                          <img className="buffer" src={buffer} alt="" />
                        </button>

                        <button className="btn" onClick={this.refreshBoxItems}>
                          <img className="load" src={load} alt="" />
                        </button>

                        <button
                          className="btn"
                          onClick={this.fetchFavouriteAPI}
                        >
                          <img
                            className={isItemBookmarked ? "" : "bookmark"}
                            src={bookmarks}
                            alt=""
                          />
                        </button>
                      </div>
                    </div>

                    {slug === "pearls" ? (
                      <div className="pearl-products pearl-steps flex">
                        <div className="pearl-color-top">
                          <img className="product-first" src={step2left}></img>
                        </div>

                        <div className="pearl-color-inner">
                          <img
                            className={`product-seconds ${boxInfo?.units}-pearls pearls${boxInfo?.units}`}
                            src={boxInfo?.img}
                            alt=""
                          ></img>

                          {boxInfo?.units === 4 ? (
                            <div id="4-pearls" className="pearl-color pearl-4">
                              {boxItemsArr?.length
                                ? boxItemsArr.map((d, i) => (
                                    <div key={i}>
                                      {d.imgUrl ? (
                                        <img
                                          onClick={() => {
                                            if (!d.imgUrl) return;
                                            this.setItemsInBox(d, "box", i);
                                          }}
                                          src={d.imgUrl}
                                          alt={d.id}
                                        ></img>
                                      ) : null}
                                    </div>
                                  ))
                                : null}
                            </div>
                          ) : boxInfo?.units === 6 ? (
                            <div id="6-pearls" className="pearl-color pearl-6">
                              {boxItemsArr?.length
                                ? boxItemsArr.map((d, i) => (
                                    <div key={i}>
                                      {d.imgUrl ? (
                                        <img
                                          onClick={() => {
                                            if (!d.imgUrl) return;
                                            this.setItemsInBox(d, "box", i);
                                          }}
                                          src={d.imgUrl}
                                          alt={d.id}
                                        ></img>
                                      ) : null}
                                    </div>
                                  ))
                                : null}
                            </div>
                          ) : boxInfo?.units === 12 ? (
                            <div
                              id="12-pearls"
                              className="pearl-color pearl-12"
                            >
                              {boxItemsArr?.length
                                ? boxItemsArr.map((d, i) => (
                                    // <div>
                                    // <img src={Pearlcolors}></img>
                                    // </div>
                                    <div key={i}>
                                      {d.imgUrl ? (
                                        <img
                                          onClick={() => {
                                            if (!d.imgUrl) return;
                                            this.setItemsInBox(d, "box", i);
                                          }}
                                          src={d.imgUrl}
                                          alt={d.id}
                                        ></img>
                                      ) : null}
                                    </div>
                                  ))
                                : null}
                            </div>
                          ) : boxInfo?.units === 18 ? (
                            <div
                              id="18-pearls"
                              className="pearl-color pearl-18"
                            >
                              {boxItemsArr?.length
                                ? boxItemsArr.map((d, i) => (
                                    // <div>
                                    // <img src={Pearlcolors}></img>
                                    // </div>
                                    <div key={i}>
                                      {d.imgUrl ? (
                                        <img
                                          onClick={() => {
                                            if (!d.imgUrl) return;
                                            this.setItemsInBox(d, "box", i);
                                          }}
                                          src={d.imgUrl}
                                          alt={d.id}
                                        ></img>
                                      ) : null}
                                    </div>
                                  ))
                                : null}
                            </div>
                          ) : boxInfo?.units === 24 ? (
                            <div
                              id="24-pearls"
                              className="pearl-color pearl-24"
                            >
                              {boxItemsArr?.length
                                ? boxItemsArr.map((d, i) => (
                                    // <div>
                                    // <img src={Pearlcolors}></img>
                                    // </div>
                                    <div key={i}>
                                      {d.imgUrl ? (
                                        <img
                                          onClick={() => {
                                            if (!d.imgUrl) return;
                                            this.setItemsInBox(d, "box", i);
                                          }}
                                          src={d.imgUrl}
                                          alt={d.id}
                                        ></img>
                                      ) : null}
                                    </div>
                                  ))
                                : null}
                            </div>
                          ) : boxInfo.units === 37 ? (
                            <div
                              id="37-pearls"
                              className="pearl-color pearl-36"
                            >
                              {boxItemsArr?.length
                                ? boxItemsArr.map((d, i) => (
                                    <div key={i}>
                                      <img
                                        onClick={() => {
                                          if (!d.imgUrl) return;
                                          this.setItemsInBox(d, "box", i);
                                        }}
                                        src={d.imgUrl}
                                        alt=""
                                      ></img>
                                    </div>
                                  ))
                                : null}
                            </div>
                          ) : (
                            <div
                              id="37-pearls"
                              className="pearl-color pearl-36"
                            >
                              {boxItemsArr?.length
                                ? boxItemsArr.map((d, i) => (
                                    <div key={i}>
                                      <img
                                        onClick={() => {
                                          if (!d.imgUrl) return;
                                          this.setItemsInBox(d, "box", i);
                                        }}
                                        src={d.imgUrl}
                                        alt=""
                                      ></img>
                                    </div>
                                  ))
                                : null}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : slug === "pipette" ? (
                      <React.Fragment>
                        <div className="step2-truffle-boxes">
                          {boxInfo?.units < 12 ? (
                            <div
                              id="without-slider"
                              className={`truffles-${boxInfo?.units}`}
                            >
                              <img
                                src={boxInfo?.img}
                                id={`${boxInfo?.units}-truffles`}
                              />
                              <div className="pearl-products-images">
                                {boxItemsArr?.length
                                  ? boxItemsArr.map((d, i) => (
                                      <div className="truffle-box-img">
                                        {d.imgUrl && (
                                          <img
                                            src={d.imgUrl}
                                            alt=""
                                            onClick={() => {
                                              if (!d.imgUrl) return;
                                              this.setItemsInBox(d, "box", i);
                                            }}
                                          />
                                        )}
                                      </div>
                                    ))
                                  : null}
                              </div>
                            </div>
                          ) : (
                            <div id="with slider" className="truffles-25">
                              <Slider
                                ref={(c) => (this.truffleBoxSlider = c)}
                                {...settings}
                              >
                                {truffleBoxArr?.length
                                  ? truffleBoxArr?.map((d, i) => (
                                      <div key={d} id={i}>
                                        <div className="pearl-products-images">
                                          {tempTruffleObj?.[i]?.length
                                            ? tempTruffleObj[i]?.map(
                                                (dd, ind) => (
                                                  <div className="truffle-box-img">
                                                    {dd.imgUrl && (
                                                      <img
                                                        onClick={() => {
                                                          if (!dd.imgUrl)
                                                            return;
                                                          this.setItemsInBox(
                                                            dd,
                                                            "box",
                                                            dd.unique_id
                                                          );
                                                        }}
                                                        src={dd.imgUrl}
                                                        alt=""
                                                        key={ind}
                                                      />
                                                    )}
                                                  </div>
                                                )
                                              )
                                            : null}
                                        </div>
                                        <img
                                          src={boxInfo?.img}
                                          id={`${boxInfo?.units}-truffles`}
                                          className={`${boxInfo?.units}-truffles ${i}`}
                                        />
                                      </div>
                                    ))
                                  : null}
                              </Slider>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="step2-truffle-boxes">
                          {boxInfo?.units < 25 ? (
                            <div
                              id="without-slider"
                              className={`truffles-${boxInfo?.units}`}
                            >
                              <img
                                src={boxInfo?.img}
                                id={`${boxInfo?.units}-truffles`}
                              />
                              <div className="pearl-products-images">
                                {boxItemsArr?.length
                                  ? boxItemsArr.map((d, i) => (
                                      <div className="truffle-box-img">
                                        {d.imgUrl && (
                                          <img
                                            src={d.imgUrl}
                                            alt=""
                                            onClick={() => {
                                              if (!d.imgUrl) return;
                                              this.setItemsInBox(d, "box", i);
                                            }}
                                          />
                                        )}
                                      </div>
                                    ))
                                  : null}
                              </div>
                            </div>
                          ) : (
                            <div id="with slider" className="truffles-25">
                              <Slider
                                ref={(c) => (this.truffleBoxSlider = c)}
                                {...settings}
                              >
                                {truffleBoxArr?.length
                                  ? truffleBoxArr?.map((d, i) => (
                                      <div key={d} id={i}>
                                        <div className="pearl-products-images">
                                          {tempTruffleObj?.[i]?.length
                                            ? tempTruffleObj[i]?.map(
                                                (dd, ind) => (
                                                  <div className="truffle-box-img">
                                                    {dd.imgUrl && (
                                                      <img
                                                        onClick={() => {
                                                          if (!dd.imgUrl)
                                                            return;
                                                          this.setItemsInBox(
                                                            dd,
                                                            "box",
                                                            dd.unique_id
                                                          );
                                                        }}
                                                        src={dd.imgUrl}
                                                        alt=""
                                                        key={ind}
                                                      />
                                                    )}
                                                  </div>
                                                )
                                              )
                                            : null}
                                        </div>
                                        <img
                                          src={boxInfo?.img}
                                          id={`${boxInfo?.units}-truffles`}
                                          className={`${boxInfo?.units}-truffles ${i}`}
                                        />
                                      </div>
                                    ))
                                  : null}
                              </Slider>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>

                  <div className="col-xl-4  col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="flex flex-wrap">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div
                          className={`truffle-inner-head pointer truffle-height ${
                            ingredientsView
                              ? "product-inactive"
                              : "product-active"
                          }`}
                          onClick={() => {
                            this.setState({ ingredientsView: 0 });
                            // setIngredientsView(0);
                          }}
                        >
                          {itemDesc?.title ? itemDesc?.title : name}
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div
                          className={`truffle-inner-head text-right pointer truffle-height ${
                            ingredientsView
                              ? "product-active"
                              : "product-inactive"
                          }`}
                          onClick={() => {
                            this.setState({ ingredientsView: 1 });
                            // setIngredientsView(1);
                          }}
                        >
                          INGREDIENTS
                        </div>
                      </div>
                    </div>

                    <div className="p1">
                      {!itemDesc?.title ? (
                        <div
                          className="pearl-content"
                          // dangerouslySetInnerHTML={{ __html: description }}
                        >
                          You need to choose item in order to view the
                          ingredients..
                        </div>
                      ) : (
                        <div
                          className="pearl-content"
                          dangerouslySetInnerHTML={{
                            __html: ingredientsView
                              ? ingredDesc.desc
                              : itemDesc?.desc,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>

                  <div className="pearl-three mt3">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div
                        className={`pearl-three-list ${
                          tableView ? "product-active" : "product-inactive"
                        }`}
                      >
                        <a
                          className="btn"
                          onClick={() => this.setState({ tableView: 1 })}
                        >
                          {" "}
                          View 1: Table
                        </a>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div
                        className={`pearl-three-list pearl-three-right ${
                          !tableView ? "product-active" : "product-inactive"
                        }`}
                      >
                        <a
                          className="btn"
                          onClick={() => this.setState({ tableView: 0 })}
                        >
                          {" "}
                          View 2: Product
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="pearl-three-slider flex">
                    {tableView ? (
                      <MainSlider
                        breakPoints={{
                          1400: 10,
                          1024: 8,
                          900: 8,
                          768: 6,
                          600: 4,
                        }}
                        params={{
                          slidesToShow: 10,
                          className: "home-page-carousel",
                          centerMode: false,
                          arrows: false,
                          infinite: false,
                          autoplay: true,
                          autoplaySpeed: 2000,
                          speed: 2000,
                          initialSlide: truffleBoxIndex,
                        }}
                      >
                        {filteredItems?.length
                          ? filteredItems.map((d, i) => (
                              <div className={"small-div"} key={i}>
                                <div>
                                  <img
                                    className="addbutton"
                                    src={addbutton}
                                    onClick={() => this.setItemsInBox(d)}
                                  ></img>
                                  <div className="tooltip">
                                    <span className="tooltiptext">
                                      {d.name}
                                    </span>
                                  </div>

                                  <img
                                    className={"small-img"}
                                    key={i}
                                    src={d.productimage}
                                    alt={d.name}
                                  />
                                </div>
                                <div className="small-text">{d.name}</div>
                              </div>
                            ))
                          : null}
                      </MainSlider>
                    ) : (
                      <MainSlider
                        breakPoints={{
                          1400: 3,
                          1024: 3,
                          900: 3,
                          768: 3,
                          600: 2,
                        }}
                        params={{
                          slidesToShow: 3,
                          slidesToScroll: 1,
                          className: "home-page-carousel",
                          centerMode: false,
                          autoplay: true,
                          arrows: false,
                          infinite: true,
                          autoplaySpeed: 2000,
                          speed: 2000,
                          // beforeChange: (current, next) => this.setState({ slideIndex: next });
                        }}
                      >
                        {items?.length
                          ? items.map((d, i) => (
                              <div className={"large-div"} key={i}>
                                <img
                                  className="addbutton"
                                  src={addbutton}
                                  onClick={() => this.setItemsInBox(d)}
                                ></img>
                                <div className="tooltip">
                                  <span className="tooltiptext">{d.name}</span>
                                </div>
                                <img
                                  className={"large-img"}
                                  src={d.tableimage}
                                  alt={d.name}
                                />
                              </div>
                            ))
                          : null}
                      </MainSlider>
                    )}
                  </div>
                </div>
              ) : (
                <div id="Step_1" className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 step-img">
                    <img src={boxInfo.featureImage} alt="" />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <div className="truffle-inner-head">OUR {name}</div>
                      <div
                        className="truffle-inner-content"
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></div>
                      <hr />

                      <div
                        className="chilli-para  mt1 mb2"
                        dangerouslySetInnerHTML={{ __html: note }}
                      ></div>
                    </div>

                    <div>
                      <div className="truffle-inner-head flex justify-space">
                        Box SIZE
                        <div>
                          £{boxInfo.price} * {boxInfo.units}
                        </div>
                      </div>
                      <div
                        className={`flex justify-space align-start product-space ${
                          slug !== "pearls" ? "square-box" : ""
                        }`}
                      >
                        {sortedBoxes?.length
                          ? sortedBoxes.map((d, i) => (
                              <button
                                className={`btn ${
                                  d.id === boxInfo.id
                                    ? "product-active"
                                    : "product-inactive"
                                }`}
                                onClick={() =>
                                  this.chooseBoxSize(d, filteredItems)
                                }
                              >
                                <img key={i} src={d.photo} alt={d.name} />
                              </button>
                            ))
                          : null}
                      </div>
                    </div>

                    <div className=" truffle-listing step-list">
                      <Filters
                        // showTypeFilters={allTags?.length}
                        showTypeFilters={false}
                        updateTagAndTypeIds={this.updateTagAndTypeIds}
                        tags={allTags}
                        types={allTypes}
                        boxItems={itemsWithTypeTagIds}
                        updateFilteredItems={this.updateFilteredItems}
                      />
                    </div>
                  </div>
                </div>
              )}

              <ExpressDileveryMsg />
            </div>
          )}

          {pageData?.slug !== "gift-voucher" ? (
            <React.Fragment>
              {contentimage ? (
                <img
                  src={contentimage}
                  className="my2 width-100"
                  alt="content-img"
                />
              ) : null}

              <div className="container truffle-content-four home-two">
                {rightimage ? (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex flex-wrap">
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 order-2">
                      <div className="home-two-left m-right">
                        <div className="home-two-head justify-right">
                          {" "}
                          {right_text_title}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: right_text_description,
                          }}
                        ></div>
                      </div>
                    </div>

                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 home-two-img1">
                      <img src={rightimage} className="float-right" alt="" />
                    </div>
                  </div>
                ) : null}

                {leftimage ? (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex flex-wrap">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 home-two-img">
                      <img src={leftimage} alt="" />
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 home-end">
                      <div className="home-two-right">
                        <div className="home-two-head">{left_text_title}</div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: left_text_description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
        </section>

        <Popup
          // {...priceAddWarningPopup}
          show={priceAddWarningPopup.msgBox.show}
          onClose={() => {
            this.setState({
              priceAddWarningPopup: {
                ...priceAddWarningPopup,
                msgBox: {
                  shownOnce: 1,
                  show: false,
                },
              },
            });
          }}
        />

        <Popup
          // {...priceAddWarningPopup}
          show={priceAddWarningPopup.giftWrap.show}
          onClose={() => {
            this.setState({
              priceAddWarningPopup: {
                ...priceAddWarningPopup,
                giftWrap: {
                  shownOnce: 1,
                  show: false,
                },
              },
            });
          }}
        />
      </MainLayout>
    );
  }
}

export default withContext(withRouter(Products));

//   const params = {
//     "boxes":[
//     {
//     "box_id" : 1,
//     "box_count" : 2
//     }
//     ],
//     "product_id": 1,
//     "category_id": 1,
//     "gifts":[
//     {
//     "gift_id" : 1
//     },
//         {
//     "gift_id" : 3
//     }
//     ],
//     "tags":[
//     {
//     "tag_id" : 1
//     },
//     {
//     "tag_id" : 2
//     }
//     ],
//     "messages":[
//     {
//     "message" : "hello"
//     },
//     {
//     "message" : "hello 1"
//     }
//     ],
//     "size_id" : 1,
//     "price" : 400,
//     "items": [
//         {
//         "items_id" : 2,
//         "item_count" : 6
//         },
//          {
//         "items_id" : 1,
//         "item_count" : 3
//         }
//     ]
// }
