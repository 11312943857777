import React, { useState } from "react";
import icons from "../common/Images";
import Fetch from "../common/Fetch";
import { withRouter } from "react-router";
import {
  priceCalculator,
  addSubtractFxn,
  checkItemBeforeAddingCart,
} from "../common/utils";
import Auth from "../common/Auth";
import TextArea from "./TextArea";
import { updateCartItemAPICount } from "../common/common-api-calls";
import { withContext } from "../context/Context";
import Popup from "./popups/PriceAddWarning";
import ExpressDileveryMsg from "./common-comps/ExpressDileveryMsg";

const { Basket } = icons;

function CustomizeGiftVoucher({ context, history, pageData }) {
  const [voucherInfo, setVoucherInfo] = useState({
    id: pageData?.sizes[0]?.id,
    name: pageData?.sizes[0]?.name,
    basePrice: parseFloat(pageData?.sizes[0]?.price),
    amount: parseFloat(pageData?.sizes[0]?.price),
    count: 1,
  });
  // const [availableUnits, setAvailableUnits] = useState(
  //   Number(pageData?.sizes[0]?.unit)
  // );
  const [messages, setMessages] = useState([{ message: "" }]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnText, setBtnText] = useState("Add To Basket");
  const [priceAddWarning, setPriceAddWarning] = useState({
    show: false,
    shownOnce: 0,
  });

  const onChooseVoucher = (data) => {
    let obj = {
      id: data.id,
      name: data.name,
      price: parseFloat(data.price),
      amount: parseFloat(data.price),
      count: 1,
      basePrice: parseFloat(data.price),
    };
    setVoucherInfo({ ...voucherInfo, ...obj });
  };

  const updateMessage = (msg) => {
    setMessages([{ message: msg }]);
  };

  const updateUnits = (type, count, basePrice) => {
    if (btnLoader || btnText === "Go To Basket") return;
    // const maxLimit = availableUnits !== NaN ? availableUnits : 5;

    let units = addSubtractFxn(type, count);
    let price = priceCalculator(basePrice, units);

    let newObj = { ...voucherInfo, amount: price, count: units, basePrice };
    setVoucherInfo(newObj);
  };

  const addToCart = (inStock) => {
    if (!voucherInfo?.count || !inStock) return;

    if (btnText === "Go To Basket") return history.push("/cart");
    const { isUserLogin, cart } = context;

    const tempParams = {
      boxes: [],
      product_id: pageData?.id,
      category_id: pageData?.category?.id,
      gifts: [],
      tags: [],
      messages,
      price: messages?.length ? 1.5 + voucherInfo?.amount : voucherInfo?.amount,
      // price: voucherInfo?.amount,
      size_id: voucherInfo?.id,
      items: [],
      counts: voucherInfo.count,
    };

    if (!isUserLogin) {
      let localData = Auth.handleLocalStorage("temp", null, "get");
      let processedData = checkItemBeforeAddingCart(
        tempParams,
        localData,
        true
      );

      let cart_id = Math.floor(Math.random() * 1000 + 1);
      const { updateIndex, finalParams } = processedData;
      if (updateIndex !== null) {
        localData[updateIndex].count = localData[updateIndex].count + 1;
        localData["params"] = { ...finalParams };
      } else {
        const beforeFinalObj = {
          boxes: [],
          basePrice: voucherInfo.basePrice,
          id: cart_id,
          cart_id,
          parent_category: pageData?.parent_category,
          product: {
            name: pageData?.name,
            photo: pageData?.photo,
            note: pageData?.note,
            id: finalParams.product_id,
            description: pageData?.description,
          },
          category: pageData?.category,
          count: voucherInfo.count,
          items: [],
          // price: voucherInfo?.amount,
          price: messages?.length ? 1.5 + voucherInfo?.amount : voucherInfo?.amount,
          size_id: voucherInfo?.id,
          // flavors: cartDetails?. ? cartDetails?.flavors : null,
          params: { ...finalParams },
        };

        localData.push(beforeFinalObj);
        Auth.handleLocalStorage("temp", localData, "set");
        setBtnText("Go To Basket");
        window.handleToastMsg(true, "Item Added Successfully");
        setTimeout(() => {
          window.handleToastMsg(false, "");
        }, 2000);
        context.getAllInCart(false);
        return;
      }

      return;
    }

    setBtnLoader(true);
    let checkItemsStatus = checkItemBeforeAddingCart(tempParams, cart);

    const { update, finalParams, cartID } = checkItemsStatus;

    if (update) {
      updateCartItemAPICount(cartID, "update")
        .then((res) => {
          if (res?.success) {
            setBtnLoader(false);
            setBtnText("Go To Basket");
            context.getAllInCart(true);
            window.handleToastMsg(true, res.message);
            setTimeout(() => {
              window.handleToastMsg(false, "");
            }, 3000);
            return;
          }

          setBtnLoader(false);
          setBtnText("Add To Basket");
          window.handleToastMsg(true, "Something went wrong");
          setTimeout(() => {
            window.handleToastMsg(false, "");
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    Fetch("prod/cart/add", { ...finalParams }, { sendToken: true }).then(
      (res) => {
        if (res?.success) {
          setBtnLoader(false);
          setBtnText("Go To Basket");
          context.getAllInCart(true);
          window.handleToastMsg(true, res.message);
          setTimeout(() => {
            window.handleToastMsg(false, "");
          }, 3000);
        } else {
          setBtnLoader(false);
          setBtnText("Add To Basket");
          window.handleToastMsg(true, "Something went wrong");
          setTimeout(() => {
            window.handleToastMsg(false, "");
          }, 3000);
        }
      }
    );
  };

  return (
    <section className="container">
      <div className="sp-top">
        <div className="sp-top__left col-xl-7 col-lg-7 col-md-7 col-sm-12">
          <img src={pageData?.photo} alt="voucher"></img>
        </div>
        <div className="sp-top__right col-xl-5 col-lg-5 col-md-5 col-sm-12">
          <h3 className="mb1"> {pageData?.name} </h3>

          <div className="sp-top__content">
            <div className="mb1">
              <div
                dangerouslySetInnerHTML={{ __html: pageData?.description }}
              ></div>
              <hr />
            </div>

            <div
              className="mb1 chilli-para"
              dangerouslySetInnerHTML={{ __html: pageData?.note }}
            ></div>

            <div className="chilli-main">
              <div className="sp-unit-price">
                <div className="sp-unit-price__price chilli-price-end">
                  <div className="sp-unit-price__head">
                    <div className="chilli-head">VOUCHER</div>
                  </div>

                  <div className="amount flex align-center">
                    {pageData?.sizes?.length
                      ? pageData?.sizes?.map((d, i) => (
                          <span
                            className={`pointer ${
                              d.id === voucherInfo?.id ? "active" : ""
                            }`}
                            key={i}
                            onClick={() => onChooseVoucher(d)}
                          >
                            {d.size}
                            {i === pageData?.sizes?.length - 1 ? null : (
                              <div className="gift-voucher-dots"></div>
                            )}
                          </span>
                        ))
                      : null}
                  </div>
                </div>

                <div className="sp-unit-price__units chilli-price-unit">
                  <div className="sp-unit-price__head">
                    {/* <div className="chilli-head">£{voucherInfo?.amount}</div> */}
                    <div className="chilli-head">
                      £{Number(voucherInfo?.basePrice)?.toFixed(2)}
                    </div>
                  </div>

                  <div className="qty">
                    <button
                      className="btn btn-plus-minus"
                      onClick={() =>
                        updateUnits(
                          "inc",
                          voucherInfo?.count,
                          voucherInfo?.basePrice
                        )
                      }
                    >
                      +
                    </button>
                    <span>{voucherInfo?.count}</span>
                    <button
                      className="btn btn-plus-minus"
                      onClick={() =>
                        updateUnits(
                          "dec",
                          voucherInfo?.count,
                          voucherInfo?.basePrice
                        )
                      }
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>

              <div className="sp-message">
                <TextArea
                  tilte="PERSONALISED MESSAGE"
                  stopTyping={!priceAddWarning.shownOnce}
                  // stopTyping={false}
                  beforeOnChange={() => {
                    if (!priceAddWarning.shownOnce) {
                      setPriceAddWarning({ ...priceAddWarning, show: true });
                    }
                  }}
                  updateParentFxn={updateMessage}
                />

                <button
                  className={`add-btn flex align-center mt2 ${
                    !pageData?.is_stock
                      ? "product-inactive"
                      : voucherInfo?.count
                      ? "product-active"
                      : "product-inactive"
                  }`}
                  onClick={() => addToCart(pageData.is_stock)}
                  disabled={!voucherInfo?.count}
                >
                  <img src={Basket} alt="" />{" "}
                  {btnLoader
                    ? "Loading..."
                    : pageData?.is_stock
                    ? btnText
                    : "Out of stock"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ExpressDileveryMsg/>

      <Popup
        {...priceAddWarning}
        onClose={() => {
          setPriceAddWarning({ ...priceAddWarning, show: false, shownOnce: 1 });
        }}
      />
    </section>
  );
}

export default withContext(withRouter(CustomizeGiftVoucher));
