const expressDeliveryMsg = {
    1: {
        title: "Gift wrapping",
        desc: "Choose from our 6 African inspired wrapping paper and make your gift extra special. Pick your favourite wrapping paper once you have picked your chocolate selection."
    },
    2: {
        title: "Add Gift Message",
        desc: "Why not add a little note to your gift to tell that special person how much they mean to you. Add your text just before checkout."
    },
    3: {
        title: "International Delivery",
        desc: `We deliver to all of the UK and all over the world. See if your location is available at checkout. If you have any issues contact us at <a href="mailto:info@darksugars.co.uk">info@darksugars.co.uk</a> and we can help you further.`
    }
}

export default expressDeliveryMsg;