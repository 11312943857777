import React, { Component } from "react";
import Layout from "../layouts/MainLayout";
import {
  updateCartItemAPICount,
  // deleteCartItemAPICount,
  // getAllDiscountCodes,
  removeItemFromCartAPI,
} from "../common/common-api-calls";
import { priceCalculator, calculatePercentage } from "../common/utils";

import Link from "../common/Link";
import Images from "../common/Images";

import { withRouter } from "react-router-dom";
import { withContext } from "../context/Context";
import ApplyCoupons from "../components/ApplyCoupons";

const { Gift, boxes } = Images;
class Cart extends Component {
  state = {
    loading: true,
    btnLoader: false,

    cartItems: [],
    totalAmount: 0,

    // allDiscountList: [],

    discountAmount: 0,
    discountedPrice: 0,
  };

  static getDerivedStateFromProps(props, state) {
    const { cart } = props.context;
    if (state.cartItems !== cart) {
      let amount = 0;
      let cart_id = null;
      cart &&
        cart?.length &&
        cart?.forEach((d) => {
          amount += parseFloat(d.price);
          if (cart_id === null) {
            cart_id = d.cart_id;
          }
        });
      return { cartItems: cart, totalAmount: amount, discountedPrice: amount };
    }

    return null; // No change to state
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { cart } = this.props.context;
    this.setState({ cartItems: cart, loading: false });
    this.props.context.handleRightCart("toggle_Cart", false);

    // getAllDiscountCodes().then((res) => {
    //   this.setState({ allDiscountList: res.discountList });
    // });
  }

  removeCartItem = async (id) => {
    const { isUserLogin, getAllInCart, cart } = this.props.context;
    if (!isUserLogin) {
      let filteredItems = cart?.filter((f) => f?.product?.id !== id);
      getAllInCart(false, false, {
        update: true,
        items: filteredItems,
        toggleCart: false,
      });
      window.handleToastMsg(true, "Item removed successfully");
      return;
    }

    const res = await removeItemFromCartAPI(id);
    if (res?.success) {
      let cartUpdate = this.state.cartItems.filter((item) => {
        return item?.product?.id !== id;
      });
      var sum = 0;
      cartUpdate?.map((item, key) => {
        sum += Number(item.price);
      });
      this.setState({ totalAmount: sum });
      window.handleToastMsg(true, res.message);
      this.props.context.getAllInCart(true);
    }
  };

  updateItemQuantity = async (action, id, prodID) => {
    const { isUserLogin, getAllInCart, cart } = this.props.context;
    const option = action === "add" ? "update" : "delete";

    console.log({ cart });

    if (!isUserLogin) {
      let temp = {};
      cart?.length &&
        cart?.forEach((d) => {
          temp[d.id] = d;
        });

      if (id in temp) {
        let innerObj = { ...temp[id] };
        let paramCopy = { ...innerObj["params"] };
        let boxes = { ...paramCopy["boxes"][0] };
        // setBtnLoader(false);
        if (option === "update") {
          innerObj["count"] = innerObj["count"] + 1;
          innerObj["price"] = priceCalculator(
            innerObj["basePrice"],
            innerObj["count"]
          );
          paramCopy["price"] = priceCalculator(
            innerObj["basePrice"],
            innerObj["count"]
          );
          // paramCopy["counts"] = paramCopy["counts"] + 1;
          paramCopy["counts"] = innerObj["count"];
          boxes["box_id"] = boxes["box_id"];
          boxes["box_count"] = paramCopy["counts"];

          paramCopy["boxes"] = paramCopy["boxes"]?.length
            ? [boxes]
            : paramCopy["boxes"];
          innerObj["params"] = paramCopy;

          temp[id] = innerObj;
          const finalArr = Object.values(temp);
          getAllInCart(false, false, {
            update: true,
            items: finalArr,
            toggleCart: false,
          });
          window.handleToastMsg(true, "Item added successfully");
          return;
        }

        if (innerObj.count === 1) {
          const finalArr = cart?.filter((d) => d.id !== id);
          getAllInCart(false, false, {
            update: true,
            items: finalArr,
            toggleCart: false,
          });
          window.handleToastMsg(true, "Item removed successfully");
          return;
        }

        innerObj["count"] = innerObj["count"] - 1;
        innerObj["price"] = priceCalculator(
          innerObj["basePrice"],
          innerObj["count"]
        );
        paramCopy["price"] = priceCalculator(
          innerObj["basePrice"],
          innerObj["count"]
        );
        paramCopy["counts"] = innerObj["count"];
        boxes["box_id"] = boxes["box_id"];
        boxes["box_count"] = innerObj["count"];
        paramCopy["boxes"] = paramCopy["boxes"]?.length
          ? [boxes]
          : paramCopy["boxes"];
        innerObj["params"] = paramCopy;

        temp[id] = innerObj;
        const finalArr = Object.values(temp);
        getAllInCart(false, false, {
          update: true,
          items: finalArr,
          toggleCart: false,
        });
        window.handleToastMsg(true, "Item removed successfully");
        return;
      }
      return;
    }

    const res = await updateCartItemAPICount(id, option, prodID);

    if (res?.success) {
      window.handleToastMsg(true, res.message);
      this.props.context.getAllInCart(true, false, { toggleCart: false });
    } else {
      window.handleToastMsg(true, res.message);
    }
    return;
  };

  /**
   *
   * @param {Number} discountAmount
   */
  getPriceAfterDiscount = (_case, discountAmount, couponType) => {
    const { totalAmount } = this.state;

    if (_case === "remove") {
      this.setState({ discountedPrice: totalAmount, discountAmount });
      return;
    }

    let discountedPrice = 0;
    if (couponType === "percent") {
      let val = calculatePercentage(
        parseFloat(totalAmount),
        parseFloat(discountAmount)
      );
      discountedPrice = parseFloat(totalAmount) - val;
    } else {
      discountedPrice = parseFloat(totalAmount) - parseFloat(discountAmount);
    }

    this.setState({ discountedPrice, discountAmount });
  };

  render() {
    const {
      totalAmount,
      loading,
      // allDiscountList,
      discountedPrice,
      discountAmount,
    } = this.state;
    const { cart } = this.props.context;

    function getItemQty(arr) {
      let count = 0;
      if (arr?.length) {
        arr.forEach((d) => {
          count += d.count;
        });

        return count;
      }
      return 1;
    }

    // console.log({loading});

    return (
      <Layout title="Cart | Dark Sugar" loading={loading}>
        <div className="container cart">
          {!loading && !cart?.length ? (
            <div className="mt3 f-14 text-color-default mb3">
              {" "}
              No items available in the cart..
            </div>
          ) : (
            <React.Fragment>
              <div className="row mb2">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <div className="cart-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>CATEGORY</th>
                          <th>PRODUCT</th>
                          <th>UNITS</th>
                          <th>quantity</th>
                          <th>EXTRA</th>
                          <th>price</th>
                        </tr>

                        {cart?.length
                          ? cart.map((d, i) => (
                              <tr key={i}>
                                {console.log({ d })}
                                <td>{d.product.name}</td>

                                <td>
                                  <img
                                    className="cart-table-img"
                                    src={d.product.photo}
                                    alt=""
                                  />
                                  <div
                                    className="flex justify-space table-edit pointer"
                                    onClick={() =>
                                      this.removeCartItem(d.product.id)
                                    }
                                  >
                                    Remove
                                  </div>
                                </td>

                                <td>
                                  <div className="table-price mb1 flex align-center">
                                    <span></span>
                                    {getItemQty(d.items)}
                                  </div>

                                  <ul>
                                    {d?.items?.map((d, key) => {
                                      return (
                                        <span key={key}>{`x${d?.count} ${
                                          d?.item[0]?.name
                                        }${
                                          key !== d?.items?.length - 1
                                            ? ", "
                                            : ""
                                        }`}</span>
                                      );
                                    })}

                                    {/* <li>x2 Sea Salt</li>
                                <li>x1 Cardamon & Orange</li>
                                <li> x2 Sea Salt</li> */}
                                  </ul>
                                </td>

                                <td>
                                  <div className="table-qty flex align-center">
                                    <button
                                      className="btn btn-plus-minus"
                                      onClick={() =>
                                        this.updateItemQuantity(
                                          "add",
                                          d.cart_id,
                                          d.product.id
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                    <span>{d.count}</span>
                                    <button
                                      className="btn btn-plus-minus"
                                      onClick={() =>
                                        this.updateItemQuantity(
                                          "delete",
                                          d.cart_id,
                                          d.product.id
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                  </div>
                                </td>

                                <td className="font-italic">
                                  <div
                                    className="header-order-person pb1"
                                    // dangerouslySetInnerHTML={{
                                    //   __html:
                                    //     "gjhgjghjghjgjhgjgh"
                                    // }}
                                  >
                                    {d?.messages && d?.messages.length
                                      ? d.messages.map((msg, msgInd) => {
                                          return (
                                            <div className="mb1">
                                              <strong>Box {msgInd + 1}:</strong>{" "}
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: msg.message,
                                                }}
                                              ></p>
                                            </div>
                                          );
                                        })
                                      : d?.params?.messages &&
                                        d?.params?.messages.length
                                      ? d?.params?.messages.map(
                                          (msg, msgInd) => {
                                            return (
                                              <div className="mb1">
                                                <strong>
                                                  Box {msgInd + 1}:
                                                </strong>{" "}
                                                <p
                                                  dangerouslySetInnerHTML={{
                                                    __html: msg.message,
                                                  }}
                                                ></p>
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>
                                </td>

                                <td>£{parseFloat(d.price)?.toFixed(2)}</td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 cart-right">
                  <div className="cart-right-head" style={{ display: "none" }}>
                    {" "}
                    £25 left for free shipping{" "}
                  </div>

                  <div className="cart-right-line mb3">
                    <span></span>{" "}
                  </div>
                  <div className="cart-total">
                    <ApplyCoupons
                      // allDiscountList={allDiscountList}
                      getPriceAfterDiscount={this.getPriceAfterDiscount}
                      priceBeforeDiscount={totalAmount?.toFixed(2)}
                    />

                    <div className="cart-total-inner flex justify-space">
                      Subtotal
                      <span>£{totalAmount?.toFixed(2)}</span>
                    </div>
                    <div className="cart-total-inner flex justify-space display-none">
                      DELIVERY
                      <span>£0</span>
                    </div>

                    <div className="cart-total-inner flex justify-space ">
                      Discount
                      <span>-£{discountAmount}</span>
                    </div>
                    <div className="cart-total-inner flex justify-space">
                      Total
                      <span className="orange">
                        £{discountedPrice?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <Link
                    className="btn btn-checkout mt2"
                    onClick={() => this.props.history.push("/checkout")}
                  >
                    CHECKOUT
                  </Link>
                  {/* <button className="btn btn-checkout mt2">CHECKOUT</button> */}
                </div>
              </div>
              <hr></hr>
            </React.Fragment>
          )}
        </div>
      </Layout>
    );
  }
}

export default withContext(withRouter(Cart));
