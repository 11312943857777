export function cleanUrlEncode(str, id) {
  const filnalStr = str.toLowerCase();
  let url = "";

  try {
    if (id) {
      if (filnalStr.includes(" ")) {
        url = `${id}-${filnalStr.replaceAll(" ", "+")}`;
      } else {
        url = `${id}-${filnalStr}`;
      }
      return url;
    }

    if (str && str.includes(" ")) {
      return filnalStr.replaceAll(" ", "+");
    }

    return filnalStr;
  } catch {
    console.error("String or id not found!");
    return "";
  }
}
export const geturl = (item) => {
  let url = "";
  if (!item.parent_category.length) {
    url = `/shop/${item.category.slug + "/" + item.slug + "?page="}${
      item.slug === "gift-vouchers" ? "vouchers" : "products"
    }`;
  } else {
    url = `/shop/${
      item.parent_category[0].slug + "/" + item.category.slug + "/" + item.slug
    }`;
  }
  return url;
};

/**
 *
 * @param {String} task
 * @param {Number} num
 * @returns Numbeer
 */
export const addSubtractFxn = function (task, num, maxLimit, minLimit) {
  const upperLimit = parseInt(maxLimit);
  const lowerLimit = parseInt(minLimit);

  let count = parseInt(num);

  if (task === "inc") {
    if (upperLimit === count) return upperLimit;
    count++;
    return count;
  }

  if (task === "dec") {
    if (lowerLimit === count) {
      return parseInt(lowerLimit);
    }

    if (count === 0) {
      return 0;
    }

    count--;
    return count;
  }

  return count;
};

/**
 *
 * @param {Number} basePrice
 * @param {Number} qty
 * @returns Number
 */
export const priceCalculator = function (basePrice, qty) {
  if (basePrice === "" || basePrice === undefined || basePrice === isNaN) {
    return 0;
  }
  return parseFloat(basePrice) * parseInt(qty);
};

/**
 *
 * @param {Boolean} bool
 * @param {String} msg
 * @param {Number} time
 */
export const toggleToastMsg = (bool, msg, time) => {
  window.handleToastMsg(bool, msg);
  setTimeout(
    () => {
      window.handleToastMsg(false, "");
    },
    time ? time : 3000
  );
};

export const plainStringConverter = (str) => {
  return str?.replaceAll("-", " ");
};

export const checkItemBeforeAddingCart = (
  params,
  cartItems,
  isLocalUpdate = false
) => {
  const finalParams = { ...params };
  if (isLocalUpdate) {
    let updateIndex = null;

    if (cartItems && !cartItems.length) return { finalParams, updateIndex };

    cartItems.forEach((item) => {

      console.log({finalParams});
      
      const [{ message }] = finalParams?.messages;


      if (
        item?.product?.id === finalParams?.product_id &&
        !message?.trim()?.length
      ) {
        if (finalParams?.size_id && finalParams?.size_id === item?.size_id) {
          updateIndex = cartItems.findIndex(
            (dd) => finalParams?.size_id === dd?.size_id
          );
          finalParams["counts"] = finalParams["counts"] + 1;
        }

        if (finalParams?.flavour_id === item?.flavors) {
          updateIndex = cartItems.findIndex((dd) => {
            return finalParams?.flavour_id === dd?.flavors;
          });
          finalParams["counts"] = finalParams["counts"] + 1;
        }
      }
    });

    return { finalParams, updateIndex };
  }

  if (cartItems && !cartItems.length){
    return { finalParams, update: false, cartID: null };
  }
    
  let cartID = null;
  let update = false;

  cartItems.forEach((item) => {
    const [{ message }] = finalParams.messages;

    if (
      finalParams?.product_id === item?.product?.id &&
      !message?.trim()?.length
    ) {
      if (finalParams?.size_id) {
        if (finalParams?.size_id === (item?.size_id?.id || item?.size_id)) {
          finalParams["counts"] = finalParams["counts"] + 1;
          cartID = item.id ? item.id : item?.cart_id ? item?.cart_id : null;
          update = true;
        }
      }

      if (finalParams?.flavour_id) {
        if (finalParams?.flavour_id === item?.flavours.id) {
          finalParams["counts"] = finalParams["counts"] + 1;
          cartID = item.id ? item.id : item?.cart_id ? item?.cart_id : null;
          update = true;
        }
      }
    }
  });

  return { finalParams, update, cartID };
};

export const calculatePercentage = (n1, n2) => {
  let total = Number(n1);
  let percentVal = Number(n2);

  if (total >= percentVal) {
    return Math.abs((percentVal / total) * 100);
  }

  return total;
};

export function timeToDateConverter(time) {
  const months = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };

  if (!time) return "";
  let date = new Date(time);

  let day = date?.getDate();
  let month = date?.getMonth();
  let year = date?.getFullYear();

  return `${day} ${months[month]}, ${year}`;
}


export const download_file = (fileURL = "", fileName = "") => {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    // var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
    var filename = fileName ? fileName : "notes_download.pdf";
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }
  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
};
