import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import icons from "../common/Images";
import {
  updateCartItemAPICount,
  removeItemFromCartAPI,
  // getAllDiscountCodes,
  // deleteCartItemAPICount,
} from "../common/common-api-calls";
import { priceCalculator, calculatePercentage } from "../common/utils";
import ApplyCoupons from "./ApplyCoupons";

const {
  Menu,
  Logo,
  logo,
  user,
  Basket,
  search,
  leftArrow,
  insta,
  fb,
  play,
  account_white,
  cross,
  Gift,
} = icons;

function CartComp({
  show,
  props,
  toggleCart,
  onClickCloseBtn,
  from = false,
  shippingCharges,
}) {
  const [data, setData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [price, setPrice] = useState(0);
  const [count, setCount] = useState([]);

  const [allDiscountList, setAllDiscountList] = useState([]);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);

  useEffect(() => {
    setData(props?.context?.cart);
    var sum = 0;
    let countData = [];

    props?.context?.cart?.map((item, key) => {
      if (item.boxes?.length) {
        countData?.push({
          id: item?.boxes?.[0]?.box[0]?.id,
          count: item?.boxes?.[0]?.count,
        });
      } else {
        countData?.push({
          id: item.id,
          count: count,
        });
      }
      sum += Number(item.price);
    });

    setCount(countData);
    setPrice(sum);
    setDiscountedPrice(sum);
  }, [props?.context?.cart]);

  // useEffect(() => {
  //   getAllDiscountCodes().then((res) => {
  //     setAllDiscountList(res.discountList);
  //   });
  // }, []);

  if (!show) return null;

  const removeCartItem = async (id) => {
    const { isUserLogin, getAllInCart, cart } = props.context;

    if (!isUserLogin) {
      let filteredItems = cart?.filter((f) => f.product.id !== id);
      getAllInCart(false, false, {
        update: true,
        items: filteredItems,
        toggleCart: false,
      });
      window.handleToastMsg(true, "Item removed successfully");
      return;
    }

    const res = await removeItemFromCartAPI(id);

    if (res?.success) {
      let cartUpdate = data.filter((item) => {
        return item?.product?.id !== id;
      });

      var sum = null;
      cartUpdate?.map((item, key) => {
        sum += Number(item.price);
      });
      setPrice(sum);
      setData(cartUpdate);
      getAllInCart(true, false, {
        update: true,
        items: cartUpdate,
        toggleCart: false,
      });
    }
  };

  const updateItemQuantity = async (action, id, product_id) => {
    const { isUserLogin, getAllInCart, cart } = props.context;
    const option = action === "add" ? "update" : "delete";
    setBtnLoader(true);

    if (!isUserLogin) {
      let temp = {};
      cart?.length &&
        cart?.forEach((d) => {
          temp[d.id] = d;
        });

      if (id in temp) {
        let innerObj = { ...temp[id] };
        let paramCopy = { ...innerObj["params"] };
        let boxes = { ...paramCopy["boxes"][0] };
        setBtnLoader(false);
        if (option === "update") {
          innerObj["count"] = innerObj["count"] + 1;
          innerObj["price"] = priceCalculator(
            innerObj["basePrice"],
            innerObj["count"]
          );
          paramCopy["price"] = priceCalculator(
            innerObj["basePrice"],
            innerObj["count"]
          );
          // paramCopy["counts"] = paramCopy["counts"] + 1;
          paramCopy["counts"] = innerObj["count"];
          boxes["box_id"] = boxes["box_id"];
          boxes["box_count"] = paramCopy["counts"];

          paramCopy["boxes"] = paramCopy["boxes"]?.length
            ? [boxes]
            : paramCopy["boxes"];
          innerObj["params"] = paramCopy;
          temp[id] = innerObj;
          const finalArr = Object.values(temp);
          getAllInCart(false, false, {
            update: true,
            items: finalArr,
            toggleCart: false,
          });
          window.handleToastMsg(true, "Item added successfully");
          return;
        }

        if (innerObj.count === 1) {
          const finalArr = cart?.filter((d) => d.id !== id);
          getAllInCart(false, false, {
            update: true,
            items: finalArr,
            toggleCart: false,
          });
          window.handleToastMsg(true, "Item removed successfully");
          return;
        }

        innerObj["count"] = innerObj["count"] - 1;
        innerObj["price"] = priceCalculator(
          innerObj["basePrice"],
          innerObj["count"]
        );
        paramCopy["price"] = priceCalculator(
          innerObj["basePrice"],
          innerObj["count"]
        );
        paramCopy["counts"] = innerObj["count"];
        boxes["box_id"] = boxes["box_id"];
        boxes["box_count"] = innerObj["count"];
        paramCopy["boxes"] = paramCopy["boxes"]?.length
          ? [boxes]
          : paramCopy["boxes"];
        innerObj["params"] = paramCopy;

        temp[id] = innerObj;
        const finalArr = Object.values(temp);
        getAllInCart(false, false, {
          update: true,
          items: finalArr,
          toggleCart: false,
        });
        window.handleToastMsg(true, "Item removed successfully");
        return;
      }
      return;
    }

    const res = await updateCartItemAPICount(id, option, product_id);
    if (res.success) {
      setBtnLoader(false);
      window.handleToastMsg(true, res.message);
      props.context.getAllInCart(true);
    } else {
      setBtnLoader(false);
      window.handleToastMsg(true, res.message);
    }
    return;
  };

  // const addCount = (val) => {
  //   const data = {
  //     boxes: [{ box_count: val.boxes[0]?.box[0]?.id, box_count: 0 }],
  //     category_id: val?.category?.id,
  //     gifts: val?.gifts ? val?.gifts : [],
  //     items: val?.items ? val?.items : [],
  //     messages: val?.messages,
  //     price: 0,
  //     product_id: val?.product?.id,
  //     size_id: val?.size_id?.id,
  //     tags: val?.tags,
  //   };
  // };

  const getPriceAfterDiscount = (_case, discountAmount, couponType) => {
    if (_case === "remove") {
      setDiscountedPrice(price);
      setDiscountValue(discountAmount);
      return;
    }

    let discountedPrice = 0;
    if (couponType === "percent") {
      let val = calculatePercentage(
        parseFloat(price),
        parseFloat(discountAmount)
      );
      discountedPrice = parseFloat(price) - val;
    } else {
      discountedPrice = parseFloat(price) - parseFloat(discountAmount);
    }
    setDiscountedPrice(discountedPrice);
    setDiscountValue(discountAmount);
  };

  if (!show) return null;

  let finalPrice = shippingCharges
    ? Number(discountedPrice) + Number(shippingCharges)
    : Number(discountedPrice);

  return (
    <div>
      {/* {showCart ? ( */}
      <div className="header-order">
        <div className="flex1 header-order-scroll">
          <div className="header-order-sum header-order-border  mb2 flex align-center justify-space">
            <div className="">ORDER SUMMARY</div>

            {from && from === "checkout" ? null : (
              <button onClick={onClickCloseBtn} className="btn">
                <img src={cross} className="float-right" alt="close"></img>
              </button>
            )}
          </div>

          {data?.length ? (
            data?.map((items, key) => {
              console.log({ items });

              return (
                <React.Fragment key={key}>
                  <div className="header-order-box header-order-border  mb1 flex align-center justify-space">
                    <div className="">
                      {items?.parent_category
                        ? items?.parent_category?.name
                        : items?.category?.name}
                    </div>
                  </div>

                  <div className="mb3">
                    <div className="header-order-list header-order-border  mb1 flex align-center justify-space">
                      <div className="">{items.product.name}</div>

                      <div className="table-price  flex align-center">
                        <span></span>
                        {items.count || 0}
                        {/* {items?.items?.length} */}
                        {/* {items?.items?.length} */}
                      </div>
                    </div>

                    <div className="flex">
                      <div className="header-order-left">
                        <img
                          className="prod_img"
                          src={items.product.photo}
                          alt=""
                        />
                        <div className="flex justify-space table-edit pointer">
                          {from !== "checkout" && (
                            <button
                              // onClick={() => removeCartItem(items.cart_id)}
                              onClick={() => removeCartItem(items?.product?.id)}
                              type="button"
                            >
                              <img src={cross} alt=""></img>
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="header-order-right">
                        <ul>
                          {items?.items?.map((d, key) => {
                            return (
                              <li
                                key={key}
                              >{`x${d?.count} ${d?.item[0]?.name}`}</li>
                            );
                          })}
                        </ul>

                        <div>
                          <div
                            className="header-order-person pb1"
                            dangerouslySetInnerHTML={{
                              __html:
                                items?.product?.note ||
                                items?.product?.description,
                            }}
                          ></div>

                          <div
                            className="header-order-person pb1"
                            // dangerouslySetInnerHTML={{
                            //   __html:
                            //     "gjhgjghjghjgjhgjgh"
                            // }}
                          >
                            {items?.messages && items?.messages.length
                              ? items.messages.map((msg, msgInd) => {
                                  return (
                                    <div className="mb1">
                                      <strong>Box {msgInd + 1}:</strong>{" "}
                                      <p dangerouslySetInnerHTML={{__html: msg.message}}></p>
                                    </div>
                                  );
                                })
                              : items?.params?.messages &&
                                items?.params?.messages.length
                              ? items?.params?.messages.map((msg, msgInd) => {
                                  return (
                                    <div className="mb1">
                                      <strong>Box {msgInd + 1}:</strong>{" "}
                                      <p dangerouslySetInnerHTML={{__html: msg.message}}></p>
                                    </div>
                                  );
                                })
                              : null}
                          </div>

                          <div className="flex justify-space header-order-price pt1 align-center">
                            <div className="table-qty flex align-center">
                              {from !== "checkout" && (
                                <button
                                  className="btn btn-plus-minus"
                                  onClick={() =>
                                    updateItemQuantity(
                                      "add",
                                      items?.cart_id,
                                      items?.product.id
                                    )
                                  }
                                >
                                  +
                                </button>
                              )}

                              <span>{items?.count || 0}</span>
                              {from !== "checkout" && (
                                <button
                                  className="btn btn-plus-minus"
                                  onClick={() =>
                                    updateItemQuantity(
                                      "delete",
                                      items?.cart_id,
                                      items?.product.id
                                    )
                                  }
                                >
                                  -
                                </button>
                              )}
                            </div>

                            <div className="header-order-rate">
                              £{parseFloat(items?.price)?.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          ) : (
            <div className="mt3 f-14"> No items available in cart </div>
          )}
        </div>

        {data.length ? (
          <div className=" cart-right">
            {/* <div className="cart-right-head"> £25 left for free shipping </div> */}
            <div className="cart-right-line mb3">
              <span></span>{" "}
            </div>

            <ApplyCoupons
              // allDiscountList={allDiscountList}
              getPriceAfterDiscount={getPriceAfterDiscount}
              priceBeforeDiscount={price?.toFixed(2)}
            />

            <div className="cart-total">
              <div className="cart-total-inner flex justify-space">
                Subtotal<span>£{price?.toFixed(2)}</span>
              </div>

              {shippingCharges ? (
                <div className="cart-total-inner flex justify-space">
                  Shipping Charges
                  <span>£{Number(shippingCharges)?.toFixed(2)}</span>
                </div>
              ) : null}

              <div className="cart-total-inner flex justify-space">
                Discount<span>-£{discountValue}</span>
              </div>
              <div className="cart-total-inner flex justify-space">
                Total
                {/* <span className="orange">£{discountedPrice?.toFixed(2)}</span> */}
                <span className="orange">£{finalPrice?.toFixed(2)}</span>
              </div>
            </div>
            {from !== "checkout" && (
              <button
                className="btn btn-checkout mt2"
                onClick={() => props.history.push("/checkout")}
              >
                CHECKOUT
              </button>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(CartComp);
